import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/common/Layout';
import Title from '../components/common/Title';
import PostList from '../components/postList/PostList';
import Pagination from '../components/common/Pagination';

type Props = {
  data: {
    allContentfulBlogPost: {
      edges: any[]
    }
  },
  pageContext: {
    prev: string|null,
    next: string|null
  }
}

export default ({ data, pageContext }: Props) => {
  const posts = data.allContentfulBlogPost.edges;

  return (
    <Layout>
      <Title />
      <PostList posts={posts} />
      <Pagination prev={pageContext.prev} next={pageContext.next}/>
    </Layout>
  )
}

export const postListQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          title
          createdAt
          tags {
            slug
            name
          }
        }
      }
    }
  }
`